.content_holder {
  height: auto;
  vertical-align: top;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.detail_group {
  flex: 0.35;
  border-right: 1px solid var(--app-border-grey);
}

.table_group {
  flex: 0.65;
  padding: 0px 0px 0px 10px;
}

.title_group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
  padding: 5px 0;
  line-height: 30px;
}

.title_label {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  font-weight: bold;
}

.hide_btn {
  margin-left: 20px;
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;
  color: var(--app-blue);
}

.bold_text {
  font-weight: bold;
}

.top_table {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding-bottom: 20px;
}

.bottom_table {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.part_edit_btn {
  font-size: 16px;
  color: var(--app-blue);
  cursor: pointer;
}

.button {
  box-shadow: 0 0.0625rem 2.5rem -1.25rem var(--app-border-grey);
  margin-right: 4%;
  width: 10em;
  text-transform: none;
  letter-spacing: 0.04rem;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  font-size: 0.9em;
}

.cancel_button {
  background-color: var(--app-border-grey);
  color: var(--text-primary);
}
.cancel_button:active,
.cancel_button:hover {
  filter: brightness(75%);
}

.save_button {
  background-color: var(--app-blue);
  color: var(--app-white);
}
.save_button:active,
.save_button:hover {
  filter: brightness(150%);
}

.save_button:disabled {
  opacity: 0.3;
}

.modal .modal_small {
  display: flex;
  justify-content: center;
  min-height: 350px;
  height: 100%;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal_small {
  min-height: 100px;
}

.backdrop {
  opacity: 0.5 !important;
}

.location_dropdown {
  width: 100%;
  height: 30%;
}

.red_text {
  color: var(--status-red);
}

.detail_value_input {
  height: 30%;
  width: 100%;
  font-size: 20px;
  text-align: left;
  border-radius: 2px;
  border: 1px solid var(--app-border-grey);
  resize: none;
  background: var(--app-white);
  flex: 1;
  overflow: hidden;
}

.commentBoxContainer {
  margin-top: 1em;
}
