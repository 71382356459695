.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.text {
  font-size: 16px;
  font-weight: 400;
}

.bottom_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.item_info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.item_info_label {
  font-weight: 600;
  flex: 1;
}

.qr_code_container {
  flex: 1;
}
