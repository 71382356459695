.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.information_title_status {
  margin-left: 10px;
  padding: 1px 12px;
  border-radius: 15px;
  background-color: var(--status-yellow);
  color: var(--app-white);
  font-weight: bold;
}
