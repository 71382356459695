.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.information_header {
  border-bottom: 1px solid var(--app-border-grey);
  padding: 15px 10px;
}

.information_title_container {
  padding: 5px 10px;
  align-items: center;
}

.information_title {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-primary);
}

.information_title_status {
  margin-left: 10px;
  padding: 1px 12px;
  border-radius: 15px;
  background-color: var(--status-yellow);
  color: var(--app-white);
  font-weight: bold;
}

.information_tab_list {
  padding: 5px 10px;
  justify-content: flex-start;
  align-items: center;
}

.information_tab {
  font-weight: bold;
  font-size: 14px;
  color: var(--app-blue);
  padding: 5px 20px;
  border-right: 1px solid var(--app-border-grey);
  cursor: pointer;
  letter-spacing: 0.5px;
}

.information_tab_active {
  color: var(--text-primary);
  cursor: default;
}

.information_tab_list > .information_tab:first-child {
  padding-left: 0px;
}

.information_tab_list > .information_tab:last-child {
  border-right: none;
}

.information_content {
  padding: 10px 20px;
  flex: 1;
}
.no_result_message {
  text-align: center;
  color: var(--app-lightgrey);
  padding: 1rem;
  font-weight: 400;
  word-break: break-all;
  margin: auto;
}

.table_header {
  border-bottom: 1px solid var(--app-border-grey);
  flex: 1;
  gap: 10px;
}

.table_column {
  flex: 1;
  padding: 10px 0px;
  align-items: flex-start;
  gap: 5px;
}

.table_column_status {
  flex: 1.5;
}

.table_column:first-child {
  padding-left: 20px;
}
.table_header_text {
  font-weight: bold;
}

.table_item {
  border-bottom: 1px solid var(--app-border-grey);
  gap: 10px;
}

.table_item_text {
  color: var(--text-primary);
  letter-spacing: 0.5px;
}

.table_item_text_active {
  color: var(--app-blue);
  text-decoration: underline;
  cursor: pointer;
}

.table_item_text_active:hover {
  font-weight: bold;
  text-decoration: underline;
  color: var(--app-blue);
}

.no_result_message {
  text-align: center;
  color: var(--app-lightgrey);
  padding: 1rem;
  font-weight: 400;
  word-break: break-all;
  margin: auto;
}
