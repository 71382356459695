.modal_container * {
  letter-spacing: 0.2px;
}

.flex_row {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}

.react_bootstrap_modal_style_override {
  transform: none !important;
  overflow-y: hidden !important;
  font-weight: 500;
  width: 800px;
  max-width: 800px;
}

.modal_top_container {
  padding: 20px 18px 12px;
}

.left_section {
  width: 40%;
  border-right: 1.5px solid var(--app-border-grey);
  margin-right: 18px;
}

.right_section {
  width: 60%;
  min-height: 400px;
}

.modal_button_container {
  padding: 10px 18px;
  display: flex;
  flex-direction: row-reverse;
  align-self: end;
  width: 100%;
  border-top: 1.5px solid var(--app-border-grey);
}

.button {
  width: 104px;
  padding: 6px 0px;
  border-radius: 3px;
}

.blue_button {
  background-color: var(--app-blue);
  color: var(--app-white);
}

.grey_button {
  background-color: var(--app-offwhite);
  margin-right: 12px;
}

.title_text {
  font-weight: 700;
  font-size: 25px;
}

.bold_text {
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 6px;
}

.ordering_container {
  font-weight: 600;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--app-border-grey);
  padding: 6px 0;
}

.sequence_header_text {
  font-weight: 700;
  font-size: 13px;
}

.position {
  width: 28%;
  text-align: center;
}

.column_name {
  margin-left: 6px;
  width: 40%;
}

.checkbox_option {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  padding: 6px 6px;
  margin-right: 18px;
  margin-bottom: -3px;
  border-radius: 3px;
  cursor: pointer;
}

.checkbox_option:hover {
  background-color: var(--app-offwhite);
}

.checkbox_selected {
  background-color: var(--xemelgo-light-blue);
}

.ordering_controls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.remove_text {
  color: var(--status-red);
  font-size: 14px;
}

.up_arrow {
  color: var(--app-blue);
  cursor: pointer;
  transform: rotate(-90deg);
}

.down_arrow {
  color: var(--app-blue);
  cursor: pointer;
  transform: rotate(90deg);
  margin-right: 16px;
}

.disabled_arrow {
  cursor: default;
  opacity: 0.3;
}
