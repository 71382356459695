.flex_row {
  display: flex;
  flex-direction: row;
}

.image_upload_component {
  height: 200px;
  width: 80%;
  justify-content: center;
  row-gap: 10px;
  margin: auto;
  margin-bottom: 12px;
  border: 2px dashed var(--app-border-grey);
  border-radius: 5px;
  padding: 0px 18px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.image_upload_component_text {
  align-self: center;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.button {
  font-weight: 400;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.02rem;
  padding: 5px 20px;
  border: none;
  border-radius: 0.15625rem;
  cursor: pointer;
  color: var(--app-white);
  text-align: center;
  font-size: 12px;
  background-color: var(--app-blue);
  white-space: nowrap;
  margin-inline: auto;
}

.button:hover {
  color: white;
  box-shadow: 0px 3px 9px 0px var(--neutral-primary);
}

.previews_container {
  min-width: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 10px;
  margin-bottom: 12px;
}

.preview_box {
  position: relative;
}

.image_container {
  display: grid;
  place-content: center;
  height: 95px;
  width: 95px;
  border: 1px solid var(--app-border-grey);
  border-radius: 2px;
}

.single_image {
  margin: auto;
  height:200px;
  width: 200px;
}

.add_images_container {
  border: 2px dashed var(--app-border-grey);
  cursor: pointer;
}

.preview_image {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.error_container {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0em 2em 1em 2em;
  color: var(--status-red);
}

.delete_icon {
  position: absolute;
  top: 0;
  right: 2px;
  cursor: pointer;
}

.upload_icon {
  margin-inline: auto;
  height: 60px;
}

.add_image_icon {
  height: 70px;  
  width: 70px;
}

.centered_preview_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
