.grid_container {
  margin: 40px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 230px);
  grid-auto-rows: minmax(250px, auto);
  justify-content: center;
  gap: 28px;
  padding: 0 40px;
}

.action_button {
  border-radius: 10px;
  box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  position: relative;
}

.action_button:hover {
  opacity: 0.85;
}

.button_text {
  font-size: 28px;
  font-weight: 900;
  line-height: 1.4;
  color: white;
  text-align: left;
  margin: 20px;
  white-space: normal;
  letter-spacing: 1px;
}

.icon_container {
  position: absolute;
  bottom: 18px;
  right: 18px;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.icon {
  width: 100%;
  height: 100%;
  z-index: 3;
}
