.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.image_container {
  display: grid;
  place-content: center;
  height: 50px;
  width: 50px;
  border: 1px solid var(--app-border-grey);
  border-radius: 2px;
  user-select: none;
}

.image {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  overflow: hidden;
}
