.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.flex_column_reverse {
  display: flex;
  flex-direction: column-reverse;
}

.paginator_container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
}

.table_pager {
  align-self: flex-end;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
}

.table_pager_button {
  padding: 3px 5px;
  color: var(--app-blue);
  border: 1px solid var(--app-border-grey);
  border-radius: 4px;
  cursor: pointer;
}

.table_pager_button:hover {
  border-color: var(--app-blue);
  color: var(--app-blue);
}

.table_pager_button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: #ccc;
  pointer-events: none;
}

.table_pager_button.disabled:hover {
  border-color: var(--app-border-grey);
  color: #ccc;
}

.disabledButton {
  color: var(--text-secondary);
}

.table_pager_number {
  margin: 0px 15px;
  letter-spacing: 0.5px;
}

.table_pager_size_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  position: relative;
}

.table_pager_size_text {
  margin: 0px 5px;
}

.table_pager_size_dropdown_button {
  display: flex;
  flex-direction: row;
  gap: 5px;
  color: var(--app-blue);
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.table_pager_size_dropdown_menu {
  position: absolute;
  top: calc(100% + 5px);
  border: 1px solid var(--app-border-grey);
  border-radius: 5px;
  right: 18px;
  background-color: var(--app-white);
  display: flex;
  flex-direction: column;
  z-index: 2;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
}

.table_pager_size_dropdown_item {
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  color: var(--text-secondary);
}

.table_pager_size_dropdown_item:hover {
  background-color: var(--app-background);
}

.table_pager_size_dropdown_item:not(:last-child) {
  border-bottom: 1px solid var(--app-border-grey);
}

.table_pager_size_dropdown_item_active {
  color: var(--app-blue);
}

.paginator_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.paginator_left_content_container {
  flex: 2;
  padding-right: 10px;
  align-content: center;
}
