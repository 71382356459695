.flex_row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  align-self: stretch;
  font-size: 14px;
}

.filter {
  background-color: var(--xemelgo-light-blue);
  color: var(--app-blue);
  text-align: center;
  border-radius: 3px;
  padding: 4px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.black_text {
  font-weight: 500;
  margin-right: 8px;
}

.clear_filters_text {
  margin-left: 8px;
  padding: 4px 10px;
  font-weight: 500;
  color: var(--app-blue);
}

.clear_filters_text:hover {
  background-color: var(--xemelgo-light-blue);
  border-radius: 3px;
}

.selected_filters_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 6px;
  row-gap: 6px;
  flex-wrap: wrap;
}

.close_icon {
  cursor: pointer;
  height: 9px;
  width: 9px;
}

.close_icon * {
  stroke-width: 14px;
}
