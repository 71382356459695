.main_container {
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative; /* this is needed for SideFilter being collapsible */
}

.right_side_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}

.top_group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 18px 0px 18px;
}

.last_updated_time_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.retry_icon {
  color: var(--app-blue);
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.last_updated_time_text {
  margin-top: 1px;
  font-size: 16px;
  color: var(--text-primary);
  font-weight: bold;
}
