.form_section_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: flex-start;
}

.input_text_container {
  display: flex;
  flex-direction: row;
}

.required_asterisk {
  font-weight: 700;
  font-size: var(--font-size-small);
  color: var(--status-red);
  margin-left: 3px;
}

.input_title {
  font-weight: 700;
  font-size: var(--font-size-small);
  color: var(--text-primary);
}

.input_container {
  flex: 1;
}

.readonly_input {
  background-color: transparent;
  border-color: transparent;
}

.extra_info_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 10px 0;
}

.item_type_image {
  width: 100px;
  height: 100px;
  border: 1px solid var(--app-border-grey);
  background-color: var(--app-white);
  box-sizing: border-box;
  border-radius: 3px;
  object-fit: contain;
}

.right_container {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subfields_container {
  padding-bottom: 5px;
}

.subfields_text_bold {
  font-weight: var(--font-size-small);
  color: var(--text-primary);
  font-weight: 700;
  font-size: 13px;
}

.subfields_text {
  white-space: normal;
  font-weight: var(--font-size-small);
  color: var(--text-primary);
  font-size: 13px;
}

.loading_input {
  width: 235px;
  height: 37px;
}

.loading_input_text {
  width: 90px;
  height: 14px;
}
