.container {
  width: 100%;
  position: relative;
}

.container * {
  color: var(--text-primary);
}

.dropdown_container {
  position: absolute;
  background-color: transparent;
  width: 100%;
  z-index: 1000;
}

.dropdown {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  background-color: white;
  margin-top: 1px;
  border: 1px solid var(--app-border-grey);
  border-radius: 3px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  width: fit-content;
  min-width: 100%;
  padding: 4px 0;
}

.dropdown_item {
  padding: 6px 8px;
  cursor: pointer;
  font-weight: 400;
  width: max-content;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  border-left: 2px solid transparent;
}

.dropdown_item:hover {
  background-color: var(--app-offwhite);
}

.selected_dropdown_item {
  border-left: 2px solid var(--app-blue);
}

.selected_options_container {
  display: flex;
  flex-direction: row;
}

.selected_count_container {
  display: flex;
  flex-direction: row;
  column-gap: 22px;
  border-top: 1px solid var(--app-border-grey);
  padding: 6px 12px 2px;
}

.clear_all_text {
  color: var(--app-blue) !important;
  font-weight: 600;
  cursor: pointer;
}

.options_list_container {
  margin-top: 8px;
}

.options_list_disabled {
  pointer-events: none;
}

.icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 4px;
  margin-bottom: 1px;
  top: 1px;
  right: 1px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--app-white);
}

.icon_container > svg {
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 33px;
  margin: auto;
}

.icon_container > svg:hover {
  opacity: 0.8;
  background-color: var(--app-border-grey);
}

.icon_container * {
  color: var(--app-blue);
}

.no_results_text {
  text-align: center;
  color: var(--app-lightgrey);
  padding: 12px 8px;
  font-weight: 400;
  word-break: break-all;
  margin: auto;
}
