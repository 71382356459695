.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.groups_container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
