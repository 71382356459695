.title_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  color: var(--inventory-primary);
  border-color: var(--inventory-secondary);
  stroke-width: 5;
}

.modal_container {
  position: absolute;
  top: 0px;
  left: unset;
  right: 0;
  transform: none !important;
  max-height: none;
  height: 100%;
  margin: 0;
  width: 960px;
  max-width: 960px !important;
}

.upload_container {
  padding: 20px 30px 20px 30px;
}
