.flex_row {
  display: flex;
  flex-direction: row;
}

.table_header {
  flex: 1;
  border-top: 2px solid var(--app-border-grey);
  border-bottom: 2px solid var(--app-border-grey);
}

.empty_list_container {
  justify-content: center;
  align-items: center;
  padding: 200px 0px;
}

.empty_list_text {
  font-weight: 500;
  color: var(--text-secondary);
}

.table_column {
  flex: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 5px;
}

.table_header_text {
  font-weight: bold;
  font-size: 14px;
}

.table_item {
  border-bottom: 1px solid var(--app-border-grey);
  margin-bottom: 1px;
  flex: 1;
}

.table_item_selectable {
  flex: 1;
}

.table_item_text {
  letter-spacing: 0.01071em;
  font-size: 14px;
  font-weight: 400;
  margin-right: 15px;
  overflow-wrap: anywhere;
}

.table_item_text_clickable {
  color: var(--app-blue);
  cursor: pointer;
}

.table_item_text_clickable:hover {
  text-decoration: underline;
}

.loading_icon {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.status_container {
  flex-wrap: wrap;
  gap: 5px;
}

.status {
  margin: 2px;
  border-radius: 15px;
  color: var(--app-white);
  padding: 1px 12px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  overflow-wrap: break-word;
  max-width: 87px;
}
