.modal_dialog {
  width: 480px;
  max-width: 480px;
}

.multi_input_container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.multi_input_field_container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
