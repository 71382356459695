.csv_upload_container {
  flex: 1;
  flex-direction: column;
}

.download_template_button_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0px;
}

.download_button {
  color: var(--app-blue);
  font-weight: bold;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

#fileList ul {
  list-style: none;
  padding: 0;
}

#fileList li {
  margin: 5px 0;
  font-size: 14px;
}
