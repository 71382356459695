.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.partial_loading_message_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: 12px;
  position: absolute;
}
