.value_unit_input_container {
  display: flex;
  background-color: white;
  border-radius: 4px;
}

.value_input {
  width: 60%;
  padding: 5px;
  border: 1px solid var(--app-border-grey);
  border-radius: inherit;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  text-align: left;
  justify-content: left;
}

.error_input {
  width: 60%;
  padding: 5px;
  border: 1px solid var(--status-red);
  border-radius: inherit;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  text-align: left;
  justify-content: left;
}

.unit_select {
  width: 40%;
  border: 1px solid var(--app-border-grey);
  border-radius: inherit;
  cursor: pointer;
  appearance: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.value_input:focus {
  outline: none;
}

.value_select:focus {
  outline: none;
}

.value_select option {
  padding: 10px;
}
