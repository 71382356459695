.container {
  position: relative;
}

.button {
  cursor: pointer;
  background-color: var(--xemelgo-light-blue);
  color: var(--xemelgo-blue);
  padding: 4px 12px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  line-height: 15px;
}

.button:hover {
  border: 1px solid var(--xemelgo-blue);
  opacity: 0.8;
}

.button:active {
  opacity: 1;
}

.icon {
  transform: scale(0.8);
}

.popup_container {
  position: absolute;
  top: 100%;
  margin-top: 5px;
  left: 0px;
  z-index: 2;
  min-width: 540px;
  width: 540px;
  transition: left 0.2s ease-in-out;
}

.popup_container.alignRight {
  left: auto;
  right: 0px;
}
