.main_container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex: 1;
  border-top: 0.1px solid var(--app-border-grey);
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  padding-left: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 12px solid var(--xemelgo-blue);
}

.left_container {
  padding: 16px;
  width: 220px;
  min-width: 220px;
  border-right: 0.1px solid var(--app-border-grey);
}

.right_container {
  width: 100%;
  padding: 16px 0px;
}

.users_list_header {
  display: flex;
  flex-direction: row;
  margin: 0px 16px;
}

.users_list_title {
  color: var(--text-primary);
  font-size: 1.5em;
  font-weight: bold;
}

.users_list_sub_title {
  color: var(--text-secondary);
  font-size: 1.5em;
  font-weight: bold;
  margin-left: 15px;
}

.add_button {
  color: var(--app-white);
  width: 10em;
  cursor: pointer;
  background-color: var(--app-blue);
  letter-spacing: 0.04rem;
  text-align: center;
  border-radius: 0.2em;
  padding: 0.5rem 0.75rem 0.5rem 0.625rem;
  margin: 16px;
  font-size: 1em;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem var(--app-blue);
}

.list_container {
  margin: 0px 6px;
}

.user_resend_container {
  display: flex;
}

.user_resend {
  cursor: pointer;
  color: var(--app-blue);
  margin: 5px 0px;
}

.user_resend_countdown {
  color: var(--text-secondary);
  margin: 5px 0px;
}

.user_resend_text {
  color: var(--text-secondary);
}

.user_text {
  color: var(--text-primary);
}

.hover_component {
  margin: 0px -30px;
  width: 60px;
}

.delete_button {
  color: var(--status-red);
  cursor: pointer;
  margin-left: 10px;
}

.edit_button {
  color: var(--app-blue);
  cursor: pointer;
}

.tab_list_style {
  overflow-y: auto;
  max-height: 600px;
}

.tab_style {
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.03rem;
  padding-top: 0.3rem;
  padding-bottom: 0.25rem;
  overflow-wrap: anywhere;
  border: none;
  border-radius: 0.15625rem;
  cursor: pointer;
  color: var(--text-primary);
  font-size: 0.85em;
  line-height: 18px;
  margin-top: 5px;
  display: flex;
  align-items: center !important;
  width: 100%;
}

.tab_style:focus {
  outline: none;
}

.tab_style:hover {
  background-color: var(--app-offwhite);
  color: var(--text-primary);
}

.disabled_tab_style {
  background-color: var(--app-offwhite);
  color: white;
  cursor: default;
  pointer-events: none;
}

.disabled_tab_style:hover {
  color: white;
  background-color: var(--app-offwhite);
  cursor: default;
  pointer-events: none;
}

.focused_tab_style {
  background-color: var(--app-blue);
  color: var(--app-white);
}

.tab_style.focused_tab_style {
  color: var(--app-white);
}

.tab_style.focused_tab_style:hover {
  background-color: var(--app-blue);
  color: var(--app-white);
}

.groupby_label {
  font-size: 1em;
  font-weight: bold;
  color: var(--text-secondary);
}
