.flex_row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  align-self: stretch;
  font-size: 14px;
}

.item {
  background-color: var(--xemelgo-light-blue);
  text-align: center;
  border-radius: 60px;
  padding: 4px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
}

.black_text {
  font-weight: 500;
  margin-right: 8px;
}

.selected_items_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
  row-gap: 6px;
  flex-wrap: wrap;
}

.close_icon {
  cursor: pointer;
  height: 11px;
  width: 11px;
}

.close_icon * {
  color: var(--app-blue) !important;
  stroke-width: 6px;
}
