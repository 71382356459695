.body_container {
  min-height: 350px;
  max-height: 350px;
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.spinner_container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.body_heading {
  font-size: 18px;
}

.location_list_container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  gap: 5px;
}

.location_list_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
