.container {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
}

.icon {
  width: 24px;
  height: 24px;
}

.table {
  border-top: 1px solid var(--app-border-grey);
  margin-top: 4px;
  max-height: 130px;
  overflow-y: scroll;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  border-bottom: 1px solid var(--app-border-grey);
  padding: 6px 10px;
  font-size: 14px;
  align-items: center;
}

.cell {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.row_bold_text {
  font-weight: 600;
}

.secondary_text {
  font-weight: 400;
  color: var(--text-secondary);
}

.remove_cell {
  flex: 0.5;
  display: flex;
  align-items: center;
}

.remove_button {
  text-align: center;
  border-radius: 3px;
  padding: 0 10px;
  height: 30px;
  background-color: var(--app-white);
  border: 1px solid var(--app-blue);
  color: var(--app-blue);
  margin-left: auto;
}

.remove_button:hover {
  background-color: var(--xemelgo-light-blue);
}
