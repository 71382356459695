.name {
  letter-spacing: 1px;
  word-break: break-all;
  width: 100%;
  max-width: 250px;
  justify-content: flex-start;
  font-weight: bolder;
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--text-primary);
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container {
  min-width: 280px;
  min-height: 120px;
  width: 100%;
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.second_container {
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.third_container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 5px;
}

.image {
  border-radius: 5px;
  height: 125px;
  width: 125px;
  align-self: center;
  object-fit: contain;
  margin-right: 10px;
  border: 1px solid var(--app-border-grey);
}

.data_group {
  display: flex;
  flex-direction: column;
  flex: 3;
  align-items: center;
}

.data_group_no_image {
  justify-content: center;
  padding-bottom: 20px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.item_label {
  /* font-weight: 500; */
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;
  white-space: nowrap;
  color: "var(--text-primary)";
  text-align: center;
  padding-right: 10px;
}

.item_value {
  font-weight: bold;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
  white-space: nowrap;
  color: "var(--text-primary)";
  text-align: center;
}
