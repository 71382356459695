.main_container {
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative; /* this is needed for SideFilter being collapsible */
}

.right_side_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}
