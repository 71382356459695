.change_data_view_button {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 5px 10px;
  color: var(--app-blue);
}

.change_data_view_button:hover {
  background: var(--app-lightblue);
}

.change_data_view_button_active {
  background: var(--app-lightblue);
}

.change_data_view_icon {
  color: inherit;
  height: 25px;
  width: 25px;
}

.change_data_view_text {
  font-weight: 500;
}

.data_view_dropdown_container {
  position: relative;
}

.data_view_dropdown_background {
  z-index: 2;
  background-color: white;
  border: 1px solid var(--app-border-grey);
  padding: 20px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
  transform: translate(0px, 10px);
  border-radius: 3px;

  display: grid;
  grid-template-columns: 200px 200px;
  gap: 30px;
}

.data_view_dropdown_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  color: var(--text-secondary);
  cursor: pointer;
}

.data_view_dropdown_item_icon_container {
  border: 1px solid var(--app-border-grey);
  border-radius: 3px;
}

.data_view_dropdown_item_icon_container_active {
  border-color: var(--app-blue);
}

.data_view_dropdown_item_active,
.data_view_dropdown_item:hover {
  color: var(--app-blue);
}

.data_view_dropdown_item:hover {
  background-color: var(--app-lightblue);
}

.data_view_dropdown_item_text {
  color: var(--text-primary);
  font-weight: 500;
}

.data_view_dropdown_item_text_active {
  color: var(--app-blue);
}
