.upload_container {
  padding: 20px 30px 20px 30px;
}

.printer_options_container {
  background-color: var(--app-offwhite);
  padding: 0px 20px;
}

.bulk_create_container {
  display: flex;
  flex-direction: column;
}

.upload_container {
  padding: 20px 30px 20px 30px;
}

.printer_options_container {
  background-color: #fbfafa;
  padding: 0px 20px;
}

.modal_container {
  position: absolute;
  top: 0px;
  right: 0;
  transform: none !important;
  max-height: none;
  min-width: 800px;
  height: 100%;
  margin: 0;
  max-width: none !important;
}

.body_container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.form_section {
  background-color: var(--app-light-grey);
  flex-direction: column;
}

.navigation_section {
  display: flex;
  align-self: center;
  flex-direction: row;
}
