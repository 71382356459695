.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid var(--app-border-grey);
  padding: 0px 3px;
}

.button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.view_mode_button {
  position: "relative";
  padding: 10px 15px 5px 15px;
  color: var(--text-primary);
  font-weight: bold;
  border-bottom: 2px solid transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.view_mode_button:hover {
  background-color: var(--app-offwhite);
  opacity: 0.6;
}

.view_mode_button_selected {
  color: var(--app-blue);
  border-bottom-color: var(--app-blue);
}

.divider {
  height: 65%;
  width: 1px;
  margin: 0px 3px;
  background-color: var(--app-border-grey);
}
