.buttonbar {
  display: flex;
  justify-content: space-between;
  padding: 1em 1em 1em 0em;
  margin-top: 1em;
}

.forms_label {
  font-weight: bold;
  display: inline-block;
}

.input_error {
  border: 1px solid red;
}

.modal_break_tag {
  border-top: 3px solid rgba(0, 0, 0, 0.1);
}

.toggler_container {
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 0;

}

.toggler_title {
  display: inline-block;
  width: 100%;
  cursor: default;
}

.toggler_title_with_padding {
  padding: 0em 1em 0em 1em;
  cursor: pointer;
}

#footer_container {
  width: 100%;
}

#footer_actions {
  width: 100%;
  display: inline-flex;
}

#footer_buttons {
  width: 100%;
  text-align: right;
}

#modal_remove_container {
  width: 50%;
  display: inline-block;
}

#level_modal-remove {
  color: #007aff;
  font-weight: normal;
  font-size: medium;
  text-align: left;
  padding-left: 0.5em;
  margin-bottom: 1em;
}

#level_modal_add {
  color: #007aff;
  font-weight: normal;
  font-size: medium;
  display: inline-block;
  width: 50%;
  text-align: right;
}

.submission_report_alerts > h4 {
  text-align: left;
  padding-bottom: 0.5em;
}

.submission_report_alerts > p {
  text-align: left;
  margin-bottom: 0em;
}

.list_view_headers {
  font-weight: bold;
}

.resources_display {
  font-size: 0.7em;
  color: var(--text-secondary);
  margin-left: 0.4em;
  font-weight: 500;
}

.optionalText {
  display: inline-block;
}

hr.styled_nav {
  border: 0.08em solid #bdbfbb;
  background-color: #bdbfbb;
  margin: initial;
  /*height: 12px;*/
  /*border: 0;*/
  /*box-shadow: inset 0 12px 12px -15px rgba(0, 0, 0, 0.5);*/
}

.resources_display {
  font-size: 0.7em;
  color: var(--text-secondary);
  margin-left: 0.4em;
  font-weight: 500;
}

.navbar {
  padding: 1;
  text-align: left;
  width: 100%;
}

.levels_navbar {
  margin-top: 0.5em;
}

.levels_navbar > .navbar {
  margin-bottom: 3em;
  box-shadow: 0px 0.5px 0px 0px;
  justify-content: flex-start;
  padding-left: 0;
}

.not_active_toggler {
  background-image: url("../../img/down_caret.png");
  background-size: 100% 100%;
  height: 0.8rem;
  filter: invert(69%) sepia(69%) saturate(5353%) hue-rotate(197deg) brightness(100%) contrast(101%);
  /* this filter converts the caret png from black into clickable blue */
}

.active_toggler {
  background-image: url("../../img/up_caret.png");
  background-size: 100% 100%;
  height: 0.8rem;
  filter: invert(69%) sepia(69%) saturate(5353%) hue-rotate(197deg) brightness(100%) contrast(101%);
}

nav > button.default_button {
  width: 100%;
  font-size: 0.7em;
}

.level_view_header {
  padding-left: 0em;
  padding-bottom: 0em;
  margin-bottom: 0em;
  font-weight: bold;
  display: inline-block;
  border-bottom: 2px solid var(--app-border-grey);
  cursor: default;
}

.level_view_header > p {
  display: inline;
  margin-left: 1em;
}

.level_view_active,
.level_view_items {
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  padding-left: 1em;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

#level_row_title {
  display: inline-block;
  width: 65%;
}

.level_icon_buttons {
  display: inline-block;
}

.icon_button {
  color: var(--app-blue);
}

.tabs:active,
.tabs.active {
  color: white;
  background-color: #003f73;
  box-shadow: 0.00675rem 2.5rem -0.15625rem var(--text-primary);
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0.15625rem;
}

.backlog_tabs {
  visibility: hidden;
}

.tabs {
  color: white;
  background-color: var(--app-blue);
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #46d2ff;
  border-radius: 0.15625rem 0.15625rem 015625rem 0.15625rem;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.backlog_toggle {
  margin: 0 auto;
  padding-bottom: 3%;
}

.manage_infrastructure_header {
  text-align: right;
  padding-bottom: 2em;
  padding-top: 1em;
}

@media only screen and (min-width: 768px) {
  .margin_maker {
    margin-top: 5%;
  }
  .levels_navbar > .navbar {
    justify-content: flex-start;
    margin-bottom: 2em;
    box-shadow: none;
    padding-bottom: 0;
  }
  .navbar {
    padding: 0;
  }
  nav > button.default_button {
    width: auto;
    margin-left: auto;
    margin-right: 1%;
    font-size: 0.8em;
  }
  #level_row_title {
    width: 80%;
  }
  .level_view_header {
    margin-right: 0;
  }
  .level_view_items {
    padding-left: 3em;
  }
  .level_view_active {
    padding-left: 3em;
  }
  .tabs {
    box-shadow: 0 0.125rem 0.5rem -0.25rem #46d2ff;
    border-radius: 2.8125rem 2.8125rem 2.8125rem 2.8125rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .tabs:active,
  .tabs.active {
    box-shadow: 0 2px 8px -5px var(--text-primary);
    border-radius: 2.8125rem 2.8125rem 2.8125rem 2.8125rem;
  }
}
