.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.partial_loading_message_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: 10px;
  position: absolute;
}

.partial_loading_message_container_with_multi_select_bar_enabled {
  top: 60px;
}
