.title_right_action_button {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 5px 10px;
  color: var(--app-blue);
  width: 110px;
}

.title_right_action_button:hover {
  background: var(--app-lightblue);
}

.title_right_action_button_icon {
  color: inherit;
  height: 25px;
  width: 25px;
}

.title_right_action_button_text {
  font-weight: 500;
}
