.react-calendar {
  border-width: 0px;
  width: 160px;
}

.react-calendar__navigation button {
  width: fit-content;
  min-width: 15px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__navigation button:disabled {
  background-color: transparent;
}

.react-calendar__navigation__label {
  width: fit-content;
  flex-grow: 0 !important;
  font-weight: 700;
  color: var(--text-primary);
  font-size: 13px;
}

.react-calendar__navigation {
  height: auto;
  margin-bottom: 6px;
  justify-content: space-between;
}

.react-calendar__month-view__days {
  row-gap: 0.5px;
}

.react-calendar__month-view__days__day {
  margin: 0;
  padding: 0;
  border-radius: 50%;
  height: 21.5px;
}

.react-calendar__tile:disabled {
  background-color: transparent !important;
  color: var(--text-secondary);
}

.react-calendar__tile--active {
  color: white !important;
  background-color: var(--app-blue);
}

.react-calendar__month-view__days__day--weekend {
  color: inherit;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--text-secondary);
}

.react-calendar__month-view__weekdays__weekday * {
  text-decoration: none !important;
  text-transform: none;
  color: var(--text-secondary);
  font-size: 9px;
  cursor: default !important;
}

.react-calendar__tile {
  font-size: 11px;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding-bottom: 12px;
  padding-top: 12px;
  color: var(--text-primary);
}

.react-calendar__tile--hasActive {
  background-color: var(--app-blue);
  color: white !important;
}

.react-calendar__tile:enabled:hover {
  background-color: var(--app-border-grey);
  color: var(--text-primary) !important;
}
