.dropdown_container {
  display: flex;
  flex-direction: row;
}

.dropdown_image_container {
  width: 50px;
  height: 50px;
  border: 1px solid var(--app-border-grey);
  border-radius: 2px;
}

.dropdown_option_title {
  font-weight: 600;
}

.dropdown_sub_text {
  color: var(--text-secondary);
}

.image_text_container {
  padding-left: 10px;
}
