.modal_container {
  position: absolute;
  top: 0px;
  right: 0;
  transform: none !important;
  max-height: none;
  width: 960px;
  max-width: 960px !important;
  height: 100%;
  margin: 0;
  left: initial;
}

.disabled_modal {
  opacity: 0.6;
  pointer-events: none;
}

.body_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.error-resolve-body {
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
}

.error-resolve-title {
  width: 100%;
}

.error-resolve-header {
  margin: 0px !important;
  padding: 0px;
}

.error-resolve-footer {
  border-top: 1.6px solid var(--app-border-grey);
  padding: 0px;
  background: var(--app-white);
}

.navigation_section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginator {
  font-weight: bold;
  padding: 20px 20px;
}

.header_container {
  display: flex;
  flex-direction: row;
  padding: 15px 30px;
  align-items: center;
}

.title_text {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-left: 20px;
}

.back_button_container {
  display: flex;
  align-items: center;
}

.back_button {
  color: var(--app-blue);
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  font-style: normal;
  padding-left: 5px;
}

.submit_button {
  height: 36px;
  background-color: var(--app-blue);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  border-radius: 3px;
  margin-left: 20px;
  padding: 0px 18px;
  /* text */
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-size-small);
  line-height: 20px;
  text-align: center;
  color: var(--app-white);
}

.footer_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.footer_message {
  color: var(--status-red);
}

.popup_modal_div {
  position: absolute;
  top: 100;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
