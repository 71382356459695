.top_section {
  padding: 5px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
}

.bottom_section {
  padding-top: 5px;
  border-top: 1px solid var(--app-border-grey);
  display: flex;
  flex-direction: row;
}

.container {
  height: 100%;
  width: 100%;
}

.image {
  height: 125px;
  width: 125px;
  justify-self: center;
  align-self: center;
  border-radius: 5px;
  border: 1px solid var(--app-border-grey);
  object-fit: contain;
}

.content_container {
  max-width: 210px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.item {
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-primary);
  padding-bottom: 5px;
}
