.tooltip {
  background-color: var(--app-white);
  padding: 8px;
  border: 1px solid var(--app-border-grey);
  border-radius: 3px;
}

.bottom_table {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.title_group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
  padding: 5px 0;
  line-height: 30px;
}

.title_label {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  font-weight: bold;
}

.container {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 5px;
}

.current_data_container {
  border: 1px solid var(--app-border-grey);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  width: 250px;
  gap: 10px;
}

.label {
  font-size: 14px;
}

.temperature_text {
  font-size: 20px;
  font-weight: bold;
}

.chart_container {
  flex: 1;
}
