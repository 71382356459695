.track_page_container * {
  letter-spacing: 0.2px;
}

.track_page_container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.right_container {
  flex: 1;
}

.top_container {
  padding: 14px 18px;
  border-bottom: 1px solid var(--app-border-grey);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main_content_container {
  padding: 14px 18px;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
}

.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.status_popup {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  z-index: 1000;
}
