.grid {
  display: grid;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.page_frame {
  border-top: 12px solid;
  background-color: var(--app-white);
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  grid-template-columns: minmax(1fr, auto);
  grid-template-rows: auto 1fr;
  flex-grow: 1;
}

.fixed_height {
  height: calc(100vh - 70px - 40px);
}

.title_container {
  border-bottom: 1px solid var(--app-border-grey);
  padding-left: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.back_button {
  height: 16px;
  width: 16px;
  stroke-width: 0.5px;
  cursor: pointer;
}

.back_button:hover {
  opacity: 0.6;
}

.title_icon {
  border: 4px solid;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
}

.sub_title {
  font-size: 15px;
  font-weight: 400;
  color: var(--text-secondary);
}

.title_right_container {
  margin-left: auto;
}

.overflow_hidden {
  overflow: hidden;
}

.title_right_skeleton {
  margin-left: auto;
  margin-right: 12px;
}
