.container * {
  font-size: 16px;
}

.container {
  height: 100%;
  justify-content: center;
  display: flex;
}

.inputs_container {
  width: 50%;
  max-width: 400px;
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.text {
  color: var(--text-primary);
  font-weight: 600;
  display: flex;
  margin-bottom: 8px;
}

.required_asterisk {
  user-select: none;
  font-weight: 700;
  font-size: var(--font-size-small);
  color: var(--status-red);
  margin-left: 3px;
}

.warning_container {
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: 15px;
  color: var(--status-orange);
}

.warning_icon {
  width: 40px;
}
