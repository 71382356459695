.form_container {
  display: flex;
  flex-direction: row;
  padding-right: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
  background-color: #fbfafa;
  display: flex;
  flex-direction: column;
}

.form_title {
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: 700;
  font-size: var(--font-size-base);
  color: var(--text-primary);
}

.section_container {
  margin-right: 40px;
  column-gap: 36px;
  row-gap: 30px;
}

.field_container {
  min-height: 63.5px;
  width: 235px;
}
