.row-with-action-component > .icons-block .hidden {
  display: none;
}

.row-with-action-component.pending-delete {
  opacity: 0.5;
  background-image: linear-gradient(to bottom right, #928e8e, #84847b);
}

.row-with-action-component > .editable-table-data-row-cell > input[type="text"] {
  text-align: left;
}

.react_switch {
  margin-right: 1em;
  margin-top: 0.4em;
}

.time-select {
  margin-right: 8px;
}

.input-error {
  border-color: red;
}

.text-cell {
  padding: 4px 6px 2.5px 6px;
}

.input-on-edit-mode {
  border: 1px solid var(--app-border-grey);
  border-radius: 4px;
  padding: 4px 6px 2.5px 6px;
}
