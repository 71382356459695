.container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.options_container {
  column-gap: 12px;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.free_text_search_container {
  flex: 1;
}

.free_text_search_input {
  padding: 7px 30px;
}

.export_icon {
  margin-right: 10px;
}

.export_button {
  font-weight: 700;
  text-align: center;
  border-radius: 4px;
  padding: 5px 19px;
  background-color: var(--app-white);
  border: 1px solid var(--app-blue);
  color: var(--app-blue);
}

.export_button:hover {
  background-color: var(--xemelgo-light-blue);
  color: var(--app-blue);
}

.blue_button {
  font-weight: 700;
  text-align: center;
  border-radius: 4px;
  padding: 7px 19px;
  background-color: var(--app-blue);
  color: var(--app-white);
}

.blue_button:hover {
  opacity: 0.9;
}

.header_row_container {
  border-width: 0px;
  padding: 6px 12px;
  border-radius: 3px 3px 0 0;
  background-color: var(--app-blue);
  border: 1px solid var(--app-border-grey);
  border-bottom-width: 0;
}

.header_row_container * {
  font-size: 15px;
  font-weight: 700;
  color: white !important;
}

.table_header {
  padding: 0;
}

.table_item_container {
  margin-bottom: 0;
  padding: 0;
}

.table_item_row {
  padding: 8px 12px;
  border-right: 1px solid var(--app-border-grey);
  border-left: 1px solid var(--app-border-grey);
}

.table_item_row:last-child {
  border-radius: 0 0 3px 3px;
}

.loading_table_item_row {
  padding: 1.5px 12px;
  border-right: 1px solid var(--app-border-grey);
  border-left: 1px solid var(--app-border-grey);
}

.loading_table_item_row:last-child {
  border-radius: 0 0 3px 3px;
}

.empty_list_container {
  border: 1px solid var(--app-border-grey);
  border-top-width: 0;
  border-radius: 0 0 3px 3px;
}
