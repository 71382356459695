.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.group_row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #f8f8f8;
  border: 1px solid var(--app-border-grey);
  border-radius: 3px;
  align-items: center;
  padding: 0px 8px 0px 0px;
  font-size: 12px;
  min-height: 34px;
}

.group_accent {
  background-color: var(--app-blue);
  width: 10px;
  overflow: hidden;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 8px;
  align-self: stretch;
}

.group_right_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  flex: 1;
  justify-content: flex-end;
}

.group_fields_container {
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
  align-items: center;
  max-width: 500px;
  margin-right: 10px;
}

.group_field {
  display: flex;
  align-items: center;
  grid-column: span 1;
  flex: 1;
  max-width: 200px;
}

.fields_skeleton {
  margin-right: 24px;
  height: 20px;
  width: 400px;
}

.field_title {
  font-weight: 700;
  margin-right: 4px;
}

.field_value {
  font-weight: 400;
}

.group_title_text {
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  text-decoration: underline;
  color: var(--app-blue);
}

.group_title_text:disabled {
  color: black;
  text-decoration: none;
  opacity: 1;
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}

.chevron {
  margin-right: 6px;
  cursor: pointer;
}

.chevron_down {
  transform: rotate(90deg);
}

.blue_text {
  font-weight: 500;
  font-size: 14px;
  color: var(--app-blue);
  text-align: right;
  min-width: 65px;
}

.empty_fields {
  flex-direction: row-reverse;
}
