.full_screen_container {
  background-color: var(--app-background);
  height: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  padding: 12px 20px 32px 20px;
  letter-spacing: 0.2px;
}

.main_app_component {
  background-color: var(--app-white);
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 100%;
  border-radius: 3px;
  border: 2px solid var(--app-border-grey);
  padding: 20px 20px 20px 20px;
}

.filter_bar_group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.free_text_search_container {
  flex: 1;
}

.full_screen_button {
  min-width: 30px;
  min-height: 30px;
  padding: 4px;
}

.full_screen_button:hover,
.full_screen_button:active {
  border: none;
  box-shadow: none;
  opacity: 1;
  color: var(--app-blue);
  cursor: pointer;
}
