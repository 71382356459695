.bulk_create_container {
  display: flex;
  flex-direction: column;
}

.upload_container {
  padding: 20px 30px 20px 30px;
}

.printer_options_container {
  background-color: var(--app-background);
  padding: 0px 20px;
}
