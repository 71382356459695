.error_screen {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 60px;
  padding: 6px;
}

.error_icon {
  height: 30vw;
  width: 30vw;
  max-height: 270px;
  max-width: 270px;
}

.text {
  font-size: 16px;
  font-weight: bold;
}
