.tab_bar_container {
  display: flex;
  flex-direction: row;
  padding: 0px 25px;
}

.separator {
  border-right: 1px solid #ccc;
  height: auto;
  margin: 2px 10px;
}

.tab_container {
  display: flex;
  flex-direction: row;
}

.tab_button {
  color: var(--app-lightblack);
  font-weight: bold;
  padding: 4px 8px 0px 8px;
  border-bottom: 3px solid var(--app-white);
  border-top: 3px solid var(--app-white);
}

.selected_button {
  color: var(--app-blue);
  font-weight: bold;
}

.selected_line {
  height: 3px;
  background-color: var(--app-blue);
  border-radius: 100px;
}
